const rootState = {
  step_1: {
    first_name: "",
    last_name: "",
    email_address: "",

    phone_number: "",
    business_name: "",
  },

  step_2: {
    address_query: "",
    address: {
      line_1: "",
      line_2: "",
      city: "",
      county: "",
      country: "",
      geo_country: "",
      postal_code: ""
    }
  },

  password: {
    password: "",
    confirm_password: ""
  },

  saved_card: {
    has_saved_card: false,
    stripe_pm_id: "",
    name_on_card: ""
  }
};  

const rootReducer = (state = rootState, action: any) => {
  switch(action.type) {
    
    case "UPDATE_STEP_1":
      return { ...state, step_1: action.payload}

    case "UPDATE_STEP_2":
      return { ...state, step_2: action.payload}

    case "UPDATE_PASSWORD":
      return { ...state, password: action.payload}

    case "UPDATE_CARD":
      return { ...state, saved_card: action.payload}

    default:
      return state;
  }
}

export default rootReducer;