import { Field } from '@fluentui/react-components';
import React, { useState, useEffect } from 'react';
import { StripeCardElementOptions, loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import env from '../../env/env';

// Load Stripe outside of the component to avoid recreating the object on every render
const stripePromise = loadStripe(env.STRIPE_PUBLIC_KEY);

interface BillingAddCreditCardProps {
  onComplete: (hasCreated: boolean, paymentMethodId: string) => void,
  onInteraction: () => void
}

const AddCreditCard: React.FC<BillingAddCreditCardProps> = ({ onComplete, onInteraction }) => {
  return (
    <Elements stripe={stripePromise}>
      <BillingAddCreditCard_Component onComplete={onComplete} onInteraction={onInteraction} />
    </Elements>
  );
};

const BillingAddCreditCard_Component: React.FC<BillingAddCreditCardProps> = ({ onComplete, onInteraction }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [nameOnCard, setNameOnCard] = useState('');
  const [isCardComplete, setIsCardComplete] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);

  useEffect(() => {
    const isComplete = nameOnCard.trim() !== '' && isCardComplete;
    setIsFormComplete(isComplete);
  }, [nameOnCard, isCardComplete]);

  /**
   * Function used to create a payment method ID...
   * 
   * @return String the payment method ID...
   */
  const create_payment_method_id = async (event: React.FormEvent): Promise<string> => {
    // event.preventDefault();

    if (!stripe || !elements) {
      return "";
    }

    setLoading(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)!,
    });

    if (error) {
      console.error(error);
      setLoading(false);
      return "";
    }
    else {
      return paymentMethod.id;
    }

    // create the new billing method against the API endpoints... 
    // Billing_Method.create_billing_method(paymentMethod.id, nameOnCard).then((paymentMethodCreated) => {
    //   onClose(true);
    //   setLoading(false);
    // });
  };

  return (
    <Field label='Card Details'>
      <div style={{ border: '1px solid grey', padding: 15, borderRadius: '4px' }}>
        <CardElement
          onChange={async (event: any) => {
            setIsCardComplete(event.complete);
            
            if(event.complete) {
              const paymentMethodId = await create_payment_method_id(event);
              onComplete(true, paymentMethodId);
            }
            else {
              onComplete(false, "");
            }
          }}
          options={{
            hidePostalCode: true
          } as StripeCardElementOptions}
        />
      </div>
    </Field>
  );
};

export default AddCreditCard;