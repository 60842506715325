import { Button, Spinner, Text } from '@fluentui/react-components';
import React from 'react';

import { CheckmarkCircle48Regular } from '@fluentui/react-icons';

export default class CreatedView extends React.Component {

  public constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <div>

        <br /><br /><br />
        <br /><br /><br />
        <br /><br /><br />
        <br /><br />
        
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CheckmarkCircle48Regular color='green' />
        </div>

        <p style={{
          textAlign: "center",
          fontSize: 17
        }}>
          Your account has been created
        </p>
        <p style={{textAlign: "center"}}>
          You can now follow the link below to access 121 Digital Console.
        </p>

        <br />
          
        <div
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
          >
          <Button onClick={() => {
            window.location.assign("https://console.121domains.co.uk");
          }} size='large'>Continue to console</Button>
        </div>
      </div>
    );
  }

}