/**
 * 121 Digital Get Started Form
 * 
 * Project: 121 Digital Get Started Form
 * Company: 121 Digital Services Limited
 * 
 * Description: [A brief description of the project. E.g., This project is designed to provide users with a seamless
 * onboarding experience through a digital form.]
 * 
 * Author: James Gibbons <jgibbons@121digital.co.uk>
 * Created Date: 24/06/2024
 * 
 * © 2024 121 Digital Services Limited. All rights reserved.
 * 
 * This source code is the property of 121 Digital Services Limited. Unauthorized copying,
 * distribution, or modification of this file, via any medium, is strictly prohibited.
 * This file is proprietary and confidential.
 * 
 */

import { Icon, Text } from '@fluentui/react';
import React from 'react';

export interface ProgressListItemProps {
  is_active: boolean,
  title: string,
  description: string,
  disabled: boolean
};

export default class ProgressListItem extends React.Component <ProgressListItemProps>{

  private styles: any = {};

  public constructor(props: any) {
    super(props);
  }

  /**
   * Function used to make minimal / fucntional style changes
   * on the fly as the view re-renders... In this case to ensure
   * the border of the selected progress box has the correct
   * border...
   * 
  */
  private update_styles() {
    this.styles = {
      progress_box: {
        width: "100%",
        height: 75,

        borderStyle: "solid",
        // borderColor: "#E7E6E6",
        borderWidth: 1,

        padding: 25
      },

      text: {}
    };

    // check if this progress box is disabled...
    if(this.props.disabled) {
      this.styles.text.color = "#b5b3b3";
    }

    // check if this is the active item...
    if(this.props.is_active) {
      this.styles.progress_box.borderColor = "black";
    }
    else {
      this.styles.progress_box.borderColor = "#E7E6E6";
    }
  }

  public render() {
    this.update_styles();

    return (
      <div style={this.styles.progress_box}>
        <Text style={this.styles.text}><b>{this.props.title}</b></Text>
        <br />
        <Text style={this.styles.text}>{this.props.description}</Text>
      </div>
    )
  }

} 