export default {

  "type": "development",

   /**
   * STRIPE publishable API key for use on this public frontend application...
   * 
   */
  //  "STRIPE_PUBLIC_KEY": "pk_test_51OS4OTHeWCB40Rj3Qt53jwH39ZSVhGEHOn7wDluSaefY3cMXX4JbNrFYXkFaEPtZiu8Pkf67QPndDCuo7EiV9TkA00MaLMFXKY",
   "STRIPE_PUBLIC_KEY": "pk_live_51OS4OTHeWCB40Rj3lOFeJMCNZO9q2DeSY8BmX2MM8RrAHJM2e4ovQp5x5tMK4RF0qnMRR06AqEMgRRFad36wFJl100FkDPr9Ve",

   "GOOGLE_MAPS_PUBLIC_KEY": "AIzaSyBMH-Shxe8fg-HgwQV6jZUT_drV3P0rJ0Y",

   /**
     * Main server URL for 121 Digital Core API
    */
  //  "API_REMOTE_SERVER": "http://localhost:8888/121Domains/api.php/",
   "API_REMOTE_SERVER": "https://api.121domains.co.uk/api.php/",

};