/**
 * 121 Digital Get Started Form
 * 
 * Project: 121 Digital Get Started Form
 * Company: 121 Digital Services Limited
 * 
 * Description: [A brief description of the project. E.g., This project is designed to provide users with a seamless
 * onboarding experience through a digital form.]
 * 
 * Author: James Gibbons <jgibbons@121digital.co.uk>
 * Created Date: 24/06/2024
 * 
 * © 2024 121 Digital Services Limited. All rights reserved.
 * 
 * This source code is the property of 121 Digital Services Limited. Unauthorized copying,
 * distribution, or modification of this file, via any medium, is strictly prohibited.
 * This file is proprietary and confidential.
 * 
 */

import { DatePicker } from "@fluentui/react-datepicker-compat";
import { TimePicker } from "@fluentui/react-timepicker-compat";
import { Calendar } from "@fluentui/react-calendar-compat";
import type { CalendarProps } from "@fluentui/react-calendar-compat";
import { DefaultButton, Icon, Link, PrimaryButton, Selection, Stack, StackItem, Text, TextField, ThemeSettingName, initializeIcons } from '@fluentui/react';
import ProgressList from '../components/ProgressList/ProgressList';
import { Button, Field, Input, LargeTitle, Spinner, Title3 } from '@fluentui/react-components';
import { toHaveStyle } from '@testing-library/jest-dom/matchers';
import PhoneField from '../components/PhoneField/PhoneField';

import React from 'react';
import AddCreditCard from "../components/AddCreditCard/AddCreditCard";
import Store from "../redux/ReduxStore";

export interface RegisterStep3View_Props {
  canContinue: (canContinue: boolean) => void 
}

interface RegisterStep3_State {
  name_on_card: string;
  card_validated: boolean;
  stripe_pm_id: string;
};

export default class RegisterStep3View extends React.Component <RegisterStep3View_Props, RegisterStep3_State>{
  private styles: any = {};

  public constructor(props: any) {
    super(props);

    // define initial state...
    this.state = {
      name_on_card: "",
      card_validated: false,
      stripe_pm_id: ""
    };
      
    this.styles = {
      form: {
        width: 400
      }
    }
  }

  private on_name_on_card_change = (event: any) => {
    this.setState({
      name_on_card: event.target.value
    }, this.on_values_change);
  }

  private on_values_change = () => {
    if(this.state.name_on_card !== "" && this.state.card_validated && this.state.stripe_pm_id !== "") {
      this.props.canContinue(true);
    }
    else {
      this.props.canContinue(false);
    }
  }

  private set_card_data = (stripePaymentMethodId: string) => {
    if(stripePaymentMethodId !== "" && this.state.name_on_card !== "") {
      Store.dispatch({
        type: "UPDATE_CARD",
        payload: {
          has_saved_card: true,
          stripe_pm_id: stripePaymentMethodId,
          name_on_card: this.state.name_on_card
        }
      });
    }
  }

  public render() {
    return (
      <div>
        <StackItem style={{paddingRight: 20}}>
          <Title3>Complete Your Account Setup</Title3>
          {/* <Text variant={"xxLarge"}><b>Get Started with 121 Digital</b></Text> */}
          <p>
            Add your first billing method now. This will enable
            your account to setup services straight away. If not, you can add a billing
            method within 121 Digital Console.
          </p>

          <br />

          <div style={this.styles.form}>
            <Field 
              label={"Name on card"}
            >
              <Input
                value={this.state.name_on_card}
                onChange={this.on_name_on_card_change}
                size="large"
              />
            </Field>

            <br />

            <AddCreditCard
              onComplete={(canContnue: boolean, paymentMethodId: string) => {
                this.setState({
                  card_validated: canContnue,
                  stripe_pm_id: paymentMethodId
                }, this.on_values_change);

                if(canContnue) {
                  this.set_card_data(paymentMethodId);
                }
              }}
              onInteraction={() => {}}
            />

            <br />
          </div>

        </StackItem>

        <br />
        <Text color="grey">
          <i>Your card will not be charged until you order a service through our platform.</i>
          <Link
            onClick={() => {
              this.props.canContinue(true);
            }}
          >Skip this step &amp; add billing method later</Link>
        </Text>
      </div>
    );
  }

}