/**
 * 121 Digital Get Started Form
 * 
 * Project: 121 Digital Get Started Form
 * Company: 121 Digital Services Limited
 * 
 * Description: [A brief description of the project. E.g., This project is designed to provide users with a seamless
 * onboarding experience through a digital form.]
 * 
 * Author: James Gibbons <jgibbons@121digital.co.uk>
 * Created Date: 25/06/2024
 * 
 * © 2024 121 Digital Services Limited. All rights reserved.
 * 
 * This source code is the property of 121 Digital Services Limited. Unauthorized copying,
 * distribution, or modification of this file, via any medium, is strictly prohibited.
 * This file is proprietary and confidential.
 * 
 */

export interface Phone_Country_Code {
  country_name: string;
  dial_code: string
};

  /**
   * Defining the globally used dial_codes array that holds the phone dial
   * codes and their country names..
   * 
   */
  export const dial_codes: Array<Phone_Country_Code> = [
    {
      "country_name": "United Kingdom",
      "dial_code": "+44"
    },
    {
      "country_name": "United States",
      "dial_code": "+1"
    },
    {
      "country_name": "Canada",
      "dial_code": "+1"
    },
    {
      "country_name": "Russia",
      "dial_code": "+7"
    },
    {
      "country_name": "Kazakhstan",
      "dial_code": "+7"
    },
    {
      "country_name": "Egypt",
      "dial_code": "+20"
    },
    {
      "country_name": "South Africa",
      "dial_code": "+27"
    },
    {
      "country_name": "Greece",
      "dial_code": "+30"
    },
    {
      "country_name": "Netherlands",
      "dial_code": "+31"
    },
    {
      "country_name": "Belgium",
      "dial_code": "+32"
    },
    {
      "country_name": "France",
      "dial_code": "+33"
    },
    {
      "country_name": "Spain",
      "dial_code": "+34"
    },
    {
      "country_name": "Hungary",
      "dial_code": "+36"
    },
    {
      "country_name": "Italy",
      "dial_code": "+39"
    },
    {
      "country_name": "Romania",
      "dial_code": "+40"
    },
    {
      "country_name": "Switzerland",
      "dial_code": "+41"
    },
    {
      "country_name": "Austria",
      "dial_code": "+43"
    },
    {
      "country_name": "Denmark",
      "dial_code": "+45"
    },
    {
      "country_name": "Sweden",
      "dial_code": "+46"
    },
    {
      "country_name": "Norway",
      "dial_code": "+47"
    },
    {
      "country_name": "Poland",
      "dial_code": "+48"
    },
    {
      "country_name": "Germany",
      "dial_code": "+49"
    },
    {
      "country_name": "Peru",
      "dial_code": "+51"
    },
    {
      "country_name": "Mexico",
      "dial_code": "+52"
    },
    {
      "country_name": "Cuba",
      "dial_code": "+53"
    },
    {
      "country_name": "Argentina",
      "dial_code": "+54"
    },
    {
      "country_name": "Brazil",
      "dial_code": "+55"
    },
    {
      "country_name": "Chile",
      "dial_code": "+56"
    },
    {
      "country_name": "Colombia",
      "dial_code": "+57"
    },
    {
      "country_name": "Venezuela",
      "dial_code": "+58"
    },
    {
      "country_name": "Malaysia",
      "dial_code": "+60"
    },
    {
      "country_name": "Australia",
      "dial_code": "+61"
    },
    {
      "country_name": "Indonesia",
      "dial_code": "+62"
    },
    {
      "country_name": "Philippines",
      "dial_code": "+63"
    },
    {
      "country_name": "New Zealand",
      "dial_code": "+64"
    },
    {
      "country_name": "Singapore",
      "dial_code": "+65"
    },
    {
      "country_name": "Thailand",
      "dial_code": "+66"
    },
    {
      "country_name": "Japan",
      "dial_code": "+81"
    },
    {
      "country_name": "South Korea",
      "dial_code": "+82"
    },
    {
      "country_name": "Vietnam",
      "dial_code": "+84"
    },
    {
      "country_name": "China",
      "dial_code": "+86"
    },
    {
      "country_name": "Turkey",
      "dial_code": "+90"
    },
    {
      "country_name": "India",
      "dial_code": "+91"
    },
    {
      "country_name": "Pakistan",
      "dial_code": "+92"
    },
    {
      "country_name": "Afghanistan",
      "dial_code": "+93"
    },
    {
      "country_name": "Sri Lanka",
      "dial_code": "+94"
    },
    {
      "country_name": "Myanmar",
      "dial_code": "+95"
    },
    {
      "country_name": "Iran",
      "dial_code": "+98"
    },
    {
      "country_name": "South Sudan",
      "dial_code": "+211"
    },
    {
      "country_name": "Morocco",
      "dial_code": "+212"
    },
    {
      "country_name": "Algeria",
      "dial_code": "+213"
    },
    {
      "country_name": "Tunisia",
      "dial_code": "+216"
    },
    {
      "country_name": "Libya",
      "dial_code": "+218"
    },
    {
      "country_name": "Gambia",
      "dial_code": "+220"
    },
    {
      "country_name": "Senegal",
      "dial_code": "+221"
    },
    {
      "country_name": "Mauritania",
      "dial_code": "+222"
    },
    {
      "country_name": "Mali",
      "dial_code": "+223"
    },
    {
      "country_name": "Guinea",
      "dial_code": "+224"
    },
    {
      "country_name": "Ivory Coast",
      "dial_code": "+225"
    },
    {
      "country_name": "Burkina Faso",
      "dial_code": "+226"
    },
    {
      "country_name": "Niger",
      "dial_code": "+227"
    },
    {
      "country_name": "Togo",
      "dial_code": "+228"
    },
    {
      "country_name": "Benin",
      "dial_code": "+229"
    },
    {
      "country_name": "Mauritius",
      "dial_code": "+230"
    },
    {
      "country_name": "Liberia",
      "dial_code": "+231"
    },
    {
      "country_name": "Sierra Leone",
      "dial_code": "+232"
    },
    {
      "country_name": "Ghana",
      "dial_code": "+233"
    },
    {
      "country_name": "Nigeria",
      "dial_code": "+234"
    },
    {
      "country_name": "Chad",
      "dial_code": "+235"
    },
    {
      "country_name": "Central African Republic",
      "dial_code": "+236"
    },
    {
      "country_name": "Cameroon",
      "dial_code": "+237"
    },
    {
      "country_name": "Cape Verde",
      "dial_code": "+238"
    },
    {
      "country_name": "São Tomé and Príncipe",
      "dial_code": "+239"
    },
    {
      "country_name": "Equatorial Guinea",
      "dial_code": "+240"
    },
    {
      "country_name": "Gabon",
      "dial_code": "+241"
    },
    {
      "country_name": "Republic of the Congo",
      "dial_code": "+242"
    },
    {
      "country_name": "Democratic Republic of the Congo",
      "dial_code": "+243"
    },
    {
      "country_name": "Angola",
      "dial_code": "+244"
    },
    {
      "country_name": "Guinea-Bissau",
      "dial_code": "+245"
    },
    {
      "country_name": "British Indian Ocean Territory",
      "dial_code": "+246"
    },
    {
      "country_name": "Seychelles",
      "dial_code": "+248"
    },
    {
      "country_name": "Sudan",
      "dial_code": "+249"
    },
    {
      "country_name": "Rwanda",
      "dial_code": "+250"
    },
    {
      "country_name": "Ethiopia",
      "dial_code": "+251"
    },
    {
      "country_name": "Somalia",
      "dial_code": "+252"
    },
    {
      "country_name": "Djibouti",
      "dial_code": "+253"
    },
    {
      "country_name": "Kenya",
      "dial_code": "+254"
    },
    {
      "country_name": "Tanzania",
      "dial_code": "+255"
    },
    {
      "country_name": "Uganda",
      "dial_code": "+256"
    },
    {
      "country_name": "Burundi",
      "dial_code": "+257"
    },
    {
      "country_name": "Mozambique",
      "dial_code": "+258"
    },
    {
      "country_name": "Zambia",
      "dial_code": "+260"
    },
    {
      "country_name": "Madagascar",
      "dial_code": "+261"
    },
    {
      "country_name": "Réunion/Mayotte",
      "dial_code": "+262"
    },
    {
      "country_name": "Zimbabwe",
      "dial_code": "+263"
    },
    {
      "country_name": "Namibia",
      "dial_code": "+264"
    },
    {
      "country_name": "Malawi",
      "dial_code": "+265"
    },
    {
      "country_name": "Lesotho",
      "dial_code": "+266"
    },
    {
      "country_name": "Botswana",
      "dial_code": "+267"
    },
    {
      "country_name": "Eswatini",
      "dial_code": "+268"
    },
    {
      "country_name": "Comoros",
      "dial_code": "+269"
    },
    {
      "country_name": "Saint Helena",
      "dial_code": "+290"
    },
    {
      "country_name": "Eritrea",
      "dial_code": "+291"
    },
    {
      "country_name": "Aruba",
      "dial_code": "+297"
    },
    {
      "country_name": "Faroe Islands",
      "dial_code": "+298"
    },
    {
      "country_name": "Greenland",
      "dial_code": "+299"
    }
  ]; 