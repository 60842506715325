import React from 'react';
import { ReactComponent as BrandLogoSvg } from './../../assets/branding/brand_new.svg';
import logo from './../../assets/branding/brand.png';
import { Button } from '@fluentui/react-components';

import { Dismiss24Regular, Dismiss20Regular, Dismiss16Regular } from '@fluentui/react-icons';

export default class Header extends React.Component {

  private styles: any = {};

  public constructor(props: any) {
    super(props);

    this.styles = {
      header: {
        width: 1000,
        height: 80,
    
        backgroundColor: "white",
        lineHeight: '80px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    
        marginTop: 100,

        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    
        zIndex: 999999,
    
        display: 'flex',
        // justifyContent: 'center',  // Centers content inside the header horizontally
        // alignItems: 'center',  // Centers content inside the header vertically
        paddingLeft: 25,
        paddingRight: 25,

        flexDirection: "row"
      },

      logo_wrapper: {
        margin: "auto"
      }
    };
  }

  public on_exit_button_click() {
    window.location.assign("https://121digital.co.uk");
  }

  public render() {
    return (
      <div style={this.styles.header}>
        {/* <BrandLogoSvg  height={40} /> */}
        <img src={logo} height={40} style={{marginTop: 20}} />

        <Button
          style={{
            float: "right",           // Aligns the button to the right
            position: "absolute",     // Allows absolute positioning
            right: 25,                // Positions the button 25px from the right edge
            marginTop: 20,            // Adds top margin
            // width: 20,                // Sets the width to 40px
            // height: 40,               // Sets the height to 40px, making it square
            padding: 10,
            paddingRight: 15,
            paddingLeft: 15     ,     // Removes internal padding
            display: "flex",          // Flexbox for centering
            alignItems: "center",     // Centers the icon vertically
            justifyContent: "center", // Centers the icon horizontally
            boxSizing: "border-box",  // Ensures padding and borders are included in the button's width and height
          }}
          icon={<Dismiss16Regular />}
          onClick={this.on_exit_button_click}
        />
      </div>  
    );
  }

}