/**
 * 121 Digital Get Started Form
 * 
 * Project: 121 Digital Get Started Form
 * Company: 121 Digital Services Limited
 * 
 * Description: [A brief description of the project. E.g., This project is designed to provide users with a seamless
 * onboarding experience through a digital form.]
 * 
 * Author: James Gibbons <jgibbons@121digital.co.uk>
 * Created Date: 24/06/2024
 * 
 * © 2024 121 Digital Services Limited. All rights reserved.
 * 
 * This source code is the property of 121 Digital Services Limited. Unauthorized copying,
 * distribution, or modification of this file, via any medium, is strictly prohibited.
 * This file is proprietary and confidential.
 * 
 */

import { DatePicker } from "@fluentui/react-datepicker-compat";
import { TimePicker } from "@fluentui/react-timepicker-compat";
import { Calendar } from "@fluentui/react-calendar-compat";
import type { CalendarProps } from "@fluentui/react-calendar-compat";
import { DefaultButton, Icon, Link, PrimaryButton, Selection, Stack, StackItem, Text, TextField, ThemeSettingName, initializeIcons } from '@fluentui/react';
import ProgressList from '../components/ProgressList/ProgressList';
import { Button, Field, Input, LargeTitle, Spinner, Title3 } from '@fluentui/react-components';
import { toHaveStyle } from '@testing-library/jest-dom/matchers';
import PhoneField from '../components/PhoneField/PhoneField';

import { useJsApiLoader, Autocomplete, Libraries } from '@react-google-maps/api';

import React from 'react';
import env from "../env/env";
import Store from "../redux/ReduxStore";
import debounce from "lodash/debounce";

interface AutoCompleteInputProps {
  onPlaceSelected: (place: google.maps.places.PlaceResult) => void;
  onChange: (value: string) => void;
  placeholder: string;
}

// Ensure to use API key and libraries from environment variables
const libraries: ('places')[] = ['places']; // Libraries needed for Places Autocomplete

/**
 * The google maps address autocomplete component....
 */
const AutocompleteInput: React.FC<AutoCompleteInputProps> = ({ onPlaceSelected, placeholder, onChange }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: env.GOOGLE_MAPS_PUBLIC_KEY,
    libraries
  });

  if (!isLoaded) {
    return <div>Loading...</div>; // Loading state
  }

  return (
    <Autocomplete
      onLoad={(autocomplete) => {
        if (onPlaceSelected) {
          autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            onPlaceSelected(place);
          });
        }
      }}
      options={{ 
        types: ['address'],

        // we will restrict the results to the UK only for now...
        // componentRestrictions: {country: "uk"}
      }} // Restrict to addresses
    >
      <Input
        type="text"
        placeholder={"Begin typing to search..."}
        value={placeholder}
        onChange={(event: any) => {
          onChange(event.target.value);
        }}
        style={{ width: '100%', padding: '8px' }}
        size="large"
      />
    </Autocomplete>
  );
};

/**
 * The main view for this registration stage.
 * 
 * 
 */
export interface RegisterStep2_Props {
  canContinue: (canContinue: boolean) => void
};
export default class RegisterStep2View extends React.Component <RegisterStep2_Props> {

  private styles: any = {};

  public constructor(props: any) {
    super(props);

    // define default state...
    this.state = {
      business_name: "",
      place: {},
      address_query: "",
      valid_address_selected: false
    };

    this.styles = {
      form: {
        width: 400
      }
    };
  }

  /**
   * React built in hook function to init on state load...
   * 
   */
  public componentDidMount(): void {
    this.load_redux_state_data();
  }

  /**
   * Function used to load the step1 data from redux so that we can auto
   * populate the business name input dialog...
   * 
   */
  public load_redux_state_data = (): void => {
    try {
      const reduxState = Store.getState();
      if(reduxState.step_1.business_name !== "") {
        this.setState({
          business_name: reduxState.step_1.business_name
        });
      }

      // check if we have any data that we can use already stored in the redux step2 data...
      if(reduxState.step_2.address_query !== "") {
        this.setState({
          address_query: reduxState.step_2.address_query
        });
      }

      // validate the view data and update the can continue of root component.
      this.validate_data();

    }
    catch(error) {
      throw error;
    }
  }

  /**
   * Function used to update the local & redux state value of the Business Name
   * as it is updated...
   * 
   */
  public on_business_name_update = (business_name: string): void => {
    try {
      this.setState({
        business_name: business_name
      });

      // get the current step1 data in the redux state...
      const reduxState = Store.getState();
      const step1ReduxData = reduxState.step_1;
      
      // update the redux state with the updated business name...
      step1ReduxData.business_name = business_name;
      Store.dispatch({
        type: "UPDATE_STEP_1",
        payload: step1ReduxData
      });

      // validate the view data and update the can continue of root component.
      this.validate_data();

    }
    catch(error) {
      throw error;
    }
  }

  private get_address_component(components: any, type: any): string {
    const component = components.find((c: any) => c.types.includes(type));
    return component ? component.long_name : "";
  }
  

  /**
   * Function called when a google maps place is selected... The purpose is
   * to validate the place, attach it to the redux state and then allow the
   * root component veiw to navigate to the next step.
   * 
   */
  public on_place_select = (place: google.maps.places.PlaceResult): void => {
    try {
      const addressComponents: google.maps.GeocoderAddressComponent[] = place.address_components as google.maps.GeocoderAddressComponent[];

      // define the parts if there is any possibility of missing address parts..
      for(let i = 1; i < 7; i++) {
        console.log(addressComponents[i]);
        if(typeof addressComponents[i] == "undefined") {
          addressComponents[i] = {
            long_name: "",
            short_name: "",
            types: [""]
          };
        }
      }
      
      const address = {
        "line_1": `${this.get_address_component(addressComponents, 'street_number')} ${this.get_address_component(addressComponents, 'route')}`,
        "line_2": this.get_address_component(addressComponents, 'sublocality_level_1') || this.get_address_component(addressComponents, 'sublocality') || this.get_address_component(addressComponents, 'neighborhood') || "",
        "city": this.get_address_component(addressComponents, 'locality') || this.get_address_component(addressComponents, 'postal_town') || "",
        "county": this.get_address_component(addressComponents, 'administrative_area_level_2') || "",
        "country": this.get_address_component(addressComponents, 'country') || "",
        "geo_country": this.get_address_component(addressComponents, 'administrative_area_level_1') || "",
        "postal_code": this.get_address_component(addressComponents, 'postal_code') || ""
      };

      // update the redux state with the selected data...
      const reduxStep2StateData = {
        address_query: place.formatted_address,
        address: address
      };
      Store.dispatch({
        type: "UPDATE_STEP_2",
        payload: reduxStep2StateData
      });

      // update the local state to display the formatted name as the query...
      this.setState({
        address_query: place.formatted_address
      });

      // validate the view data and update the can continue of root component.
      this.validate_data();

    }
    catch(error) {
      throw error;
    }
  }

  /**
   * Function used to validate the data that has been inputted on the step view,
   * and fire the canContinue callback...
   * 
   */
  public validate_data = () => {
    const state = this.state as any;
    const reduxState = Store.getState();

    // const addressRequiredItems = [
    //   "line_1",
    //   "line_2",
    //   "city",
    //   "country",
    //   "county",
    //   "geo_country",
    //   "postal_code"
    // ];
    // const hasValidAddressSelected = true;
    // for(let key of addressRequiredItems) {
    //   if(reduxState.step_2[key] == "") {
    //     hasValidAddressSelected = false;
    //   }
    // }

    if(reduxState.step_2.address.line_1 !== "" && reduxState.step_1.business_name) {
      this.props.canContinue(true);
    }
    else {
      this.props.canContinue(false); 
    }
  }

  /**
   * Render the view...
   */
  public render() {
    const state = this.state as any;
    return (
      <div>
        <StackItem>
          <Title3>Get Started with 121 Digital</Title3>
          {/* <Text variant={"xxLarge"}><b>Get Started with 121 Digital</b></Text> */}
          <p>Create Your Organisation</p>

          <br />

          <div style={this.styles.form}>
            <Field 
              label={"Your Business Name"}
            >
              <Input 
                value={state.business_name}
                size="large"
                onChange={(event: any) => {
                  this.on_business_name_update(event.target.value);
                }}
              />
            </Field>

            <br />

            <Field 
              label={"Business Address"}
            >
              {/* <Input size="large" placeholder="Begin typing to search..." /> */}
              <AutocompleteInput 
                onPlaceSelected={this.on_place_select} 
                placeholder={state.address_query} 
                onChange={(query_value: string) => {
                  this.setState({
                    address_query: query_value
                  });
                }}
              />
            </Field>

            <br />
          </div>

        </StackItem>
      </div>
    );
  }

}