import React, { Component } from 'react';
import { Button, Dialog, Field, Input, ProgressBar, DialogSurface, DialogTitle, DialogActions, Switch, Checkbox } from '@fluentui/react-components';
import { IStyleFunctionOrObject } from '@fluentui/react';
import debounce from 'lodash/debounce';
import Store from '../../redux/ReduxStore';

// Function to calculate password strength
const calculate_password_strength = (password: string): number => {
  let strength = 0;

  // Length criteria
  const lengthCriteria = 12;
  if (password.length >= lengthCriteria) strength += 20;
  else if (password.length >= 8) strength += 10;

  // Uppercase letters
  if (/[A-Z]/.test(password)) strength += 15;

  // Numbers
  if (/[0-9]/.test(password)) strength += 15;

  // Special characters
  if (/[^A-Za-z0-9]/.test(password)) strength += 15;

  // Lowercase letters (ensures mix of character types)
  if (/[a-z]/.test(password)) strength += 10;

  // Avoid common sequences (this is a basic example, can be expanded)
  const commonSequences = ['123', 'password', 'abc'];
  commonSequences.forEach(seq => {
    if (password.toLowerCase().includes(seq)) strength -= 10;
  });

  // Avoid repetitive characters
  const hasRepeats = /(\w)\1{2,}/.test(password);
  if (hasRepeats) strength -= 10;

  // Avoid dictionary words
  const dictionaryWords = ['password', 'letmein', '123456'];
  dictionaryWords.forEach(word => {
    if (password.toLowerCase().includes(word)) strength -= 15;
  });

  // Cap the strength to 100
  return Math.min(strength, 100);
};

interface SetPasswordDialogProps {
  isOpen: boolean;
  onComplete: () => void;
  onDismiss: () => void;
}

interface SetPasswordDialogState {
  password: string;
  confirm_password: string;
  strength: number;
  password_is_weak: boolean;
  weak_password_confirmed: boolean;
  passwords_valid: boolean;
}

export default class PasswordDialog extends Component<SetPasswordDialogProps, SetPasswordDialogState> {
  constructor(props: SetPasswordDialogProps) {
    super(props);
    this.state = {
      password: '',
      confirm_password: "",

      strength: 0,

      password_is_weak: false,
      weak_password_confirmed: false,
      passwords_valid: false
    };

    // this.validate_passwords = debounce(this.validate_passwords, 500);
  }

  private on_password_change = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      strength: calculate_password_strength(this.state.password)
    });

    // check if the passwords are valid...
    this.validate_passwords();

    // // check if the password strength is weak...
    // if(this.get_strength_indicator(this.state.strength) == "Weak") {
    //   this.setState({
    //     password_is_weak: true,
    //     passwords_valid: passwordValid
    //   });
    // }
    // else {
    //   this.setState({
    //     password_is_weak: false,
    //     passwords_valid: passwordValid
    //   });
    // }
  };

  private get_strength_colour = (strength: number): "warning" | "success" | "brand" | "error" | undefined => {
    if (strength < 50) return 'error';
    if (strength < 75) return 'warning';
    return 'success';
  };

  private get_strength_indicator = (strength: number): string => {
    if (strength < 50) return 'Weak';
    if (strength < 75) return 'Average';
    return 'Strong';
  };

  private can_confirm_password = () => {
    if(this.state.password_is_weak) {
      if(this.state.weak_password_confirmed && this.state.passwords_valid) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return this.state.passwords_valid;
    }
  } 

  private validate_passwords = (): void => {
    if(this.state.password !== "" && this.state.confirm_password !== "") {
      const valid = (this.state.password === this.state.confirm_password);
      this.setState({
        passwords_valid: valid
      });
    }
    else {
      this.setState({
        passwords_valid: false
      });
    }
  }

  private confirm_password = (): void => {
    // update the redux state with our new password...
    Store.dispatch({
      type: "UPDATE_PASSWORD",
      payload: {
        password: this.state.password,
        confirm_password: this.state.confirm_password
      }
    });

    // call the onComplete function...
    this.props.onComplete();
  }

  public render() {
    const { isOpen, onDismiss } = this.props;
    const { password, strength } = this.state;

    // Define custom styles for the dialog
    const dialogStyles: IStyleFunctionOrObject<any, any> = {
      main: { width: '600px', height: '400px' }, // Set the width and height here
    };

    return (
      <Dialog 
        open={isOpen}
        // styles={dialogStyles}
      >
        <DialogSurface>
          <DialogTitle>Set your account password</DialogTitle>

          <br />

          <Field label={"New Password"}>
            <Input 
              type='password' 
              size='large' 
              value={password} 
              onChange={(event) => {
                this.setState({
                  password: event.target.value
                });
                this.on_password_change(event);
              }}
            />
          </Field>

          <br />

          <Field label={"Confirm Password"}>
            <Input 
              type='password' 
              size='large'
              value={this.state.confirm_password}
              onChange={(event) => {
                this.setState({
                  confirm_password: event.target.value
                }, this.validate_passwords)
                this.on_password_change(event);
              }}
            />
          </Field>

          <br />

          <Field label={"Password Strength"}>
            <ProgressBar
              value={strength}
              max={100}
              style={{
                height: 10,
              }}
              color={this.get_strength_colour(strength)}
            />
            <small><span>{strength} / 100</span> - {this.get_strength_indicator(strength)}</small>
            
            {this.state.password_is_weak? (
              <div style={{marginLeft: -10, marginTop: 10}}>
                <Checkbox 
                  label={"Confirm use of weak password"} 
                  onChange={(event) => {
                    this.setState({
                      weak_password_confirmed: event.target.checked
                    }, this.validate_passwords);
                  }}
                />
              </div>
            ): (<div />)}
          </Field>

          <br /><br />

          <DialogActions>
            <Button onClick={onDismiss}>Close</Button>
            <Button
              onClick={this.confirm_password}
              disabled={!this.can_confirm_password()} 
              type="button" 
              appearance='primary'>Continue</Button>
          </DialogActions>
        </DialogSurface>
      </Dialog>
    );
  }
}
