/**
 * 121 Digital Get Started Form
 * 
 * Project: 121 Digital Get Started Form
 * Company: 121 Digital Services Limited
 * 
 * Description: [A brief description of the project. E.g., This project is designed to provide users with a seamless
 * onboarding experience through a digital form.]
 * 
 * Author: James Gibbons <jgibbons@121digital.co.uk>
 * Created Date: 24/06/2024
 * 
 * © 2024 121 Digital Services Limited. All rights reserved.
 * 
 * This source code is the property of 121 Digital Services Limited. Unauthorized copying,
 * distribution, or modification of this file, via any medium, is strictly prohibited.
 * This file is proprietary and confidential.
 * 
 */

import React from 'react';
import { Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles } from '@fluentui/react';
import {
  FluentProvider,
  webLightTheme,
  Button
} from "@fluentui/react-components";
import logo from './logo.svg';
import './App.css';
import Header from './components/Header/Header';
import RegisterView from './views/RegisterView';
import Footer from './components/Footer/Footer';

import backgroundImage from './assets/backgrounds/default.jpg';

const boldStyle: Partial<ITextStyles> = { root: { fontWeight: FontWeights.semibold } };
const stackTokens: IStackTokens = { childrenGap: 15 };
const stackStyles: Partial<IStackStyles> = {
  root: {
    width: '960px',
    margin: '0 auto',
    textAlign: 'center',
    color: '#605e5c',
  },
};

export const App: React.FunctionComponent = () => {

  // define page meta...
  document.title = "121 Digital :: Get Started";

  return (
    <div>
      <FluentProvider theme={webLightTheme}>
        <div>
          <Header />

          <RegisterView />

          <Footer />
        </div>
      </FluentProvider>
    </div>
  );
};
