/**
 * 121 Digital Get Started Form
 * 
 * Project: 121 Digital Get Started Form
 * Company: 121 Digital Services Limited
 * 
 * Description: [A brief description of the project. E.g., This project is designed to provide users with a seamless
 * onboarding experience through a digital form.]
 * 
 * Author: James Gibbons <jgibbons@121digital.co.uk>
 * Created Date: 24/06/2024
 * 
 * © 2024 121 Digital Services Limited. All rights reserved.
 * 
 * This source code is the property of 121 Digital Services Limited. Unauthorized copying,
 * distribution, or modification of this file, via any medium, is strictly prohibited.
 * This file is proprietary and confidential.
 * 
 */

import { ComboBox, IComboBoxOption, Stack, TextField } from '@fluentui/react';
import { Dropdown, Field, Input, Label, Option, Select, Text } from '@fluentui/react-components';
import React from 'react';
import { dial_codes } from '../../lib/PhoneCountryCodes/PhoneCountryCodes';

export interface PhoneField_Props {
  onChange: (event: any) => void,
  value: any
}

export default class PhoneField extends React.Component <PhoneField_Props> {
  
  private styles: any = {};
  private area_code_options: Array<any> = [];

  public constructor(props: any) {
    super(props);

    this.area_code_options = [
      // { key: '44', text: '+44' },
      // Add more area codes as needed
    ];


    // loop through our dial code array and append them to the dropdown...
    for(let dial_code_index in dial_codes) {
      const dial_code = dial_codes[dial_code_index];

      this.area_code_options.push({
        key: dial_code_index,
        text: dial_code.dial_code
      });
    }

    this.styles = {
      container: {
        display: 'flex',
        flexDirection: "column",
        // gap: 15,
      },
      dropdown: {
        width: 50,
      },
      textField: {
        flexGrow: 1,
      },

      phone_wrapper: {
        width: "20%",
        float: "left"
      },
      input_wrapper: {
        width: "79%",
        float: "right"
      }
    };

  }

  public render() {
    return (
      <div className={this.styles.container}>
        <div style={{width: "100%"}}>
          <Label>Phone Number</Label>
        </div>
        <div style={this.styles.phone_wrapper}>
          <Select
            placeholder="Select area code"
            style={{width: "100%"}}
            // value={areaCode}
            // onOptionSelect={onAreaCodeChange}
          >
            {this.area_code_options.map((option) => (
              <option key={option.key} value={option.text}>
                {option.text}
              </option>
            ))}
          </Select>
        </div>
        <div style={this.styles.input_wrapper}>
          <Field
            placeholder="Phone number"
            className={this.styles.textField}
            // value={phoneNumber}
            // onChange={onPhoneNumberChange}
          >
            <Input value={this.props.value} onChange={(event: any) => {
              this.props.onChange(event);
            }}/>
          </Field>
        </div>
      </div>
    );
  }

}