import { Spinner, Text } from '@fluentui/react-components';
import React from 'react';

export default class CreatingView extends React.Component {

  public constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <div>

        <br /><br /><br />
        <br /><br /><br />
        <br /><br /><br />
        <br /><br />
        
        <Spinner size='huge' />
        <p style={{
          textAlign: "center",
          fontSize: 17
        }}>
          Creating your account
        </p>
        <p style={{textAlign: "center"}}>
          Please wait while your account is created.
        </p>
      </div>
    );
  }

}