/**
 * 121 Digital Get Started Form
 * 
 * Project: 121 Digital Get Started Form
 * Company: 121 Digital Services Limited
 * 
 * Description: [A brief description of the project. E.g., This project is designed to provide users with a seamless
 * onboarding experience through a digital form.]
 * 
 * Author: James Gibbons <jgibbons@121digital.co.uk>
 * Created Date: 24/06/2024
 * 
 * © 2024 121 Digital Services Limited. All rights reserved.
 * 
 * This source code is the property of 121 Digital Services Limited. Unauthorized copying,
 * distribution, or modification of this file, via any medium, is strictly prohibited.
 * This file is proprietary and confidential.
 * 
 */

export enum Signup_Registration_Type {
  DEFAULT, PARTNER
}

export default class Registration_Type {

  /**
   * Function used to get the registrstion type of the account based on the
   * URL paramaters...
   * 
   * @return Signup_Registration_Type the current registraton type;
   * 
   */
  public static get_registration_type(): Signup_Registration_Type {

    // define the signup registration type...
    let registration_type = Signup_Registration_Type.DEFAULT;
    const urlParams = new URLSearchParams(window.location.search);
    if(typeof urlParams.get("type") !== 'undefined') {
      if(urlParams.get("type") == "partner") {
        registration_type = Signup_Registration_Type.PARTNER;
      }
      else {
        registration_type = Signup_Registration_Type.DEFAULT;
      }
    }

    // return the signup registration type...
    return registration_type;
  }

}

