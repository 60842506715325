/**
 * 121 Digital Get Started Form
 * 
 * Project: 121 Digital Get Started Form
 * Company: 121 Digital Services Limited
 * 
 * Description: [A brief description of the project. E.g., This project is designed to provide users with a seamless
 * onboarding experience through a digital form.]
 * 
 * Author: James Gibbons <jgibbons@121digital.co.uk>
 * Created Date: 25/06/2024
 * 
 * © 2024 121 Digital Services Limited. All rights reserved.
 * 
 * This source code is the property of 121 Digital Services Limited. Unauthorized copying,
 * distribution, or modification of this file, via any medium, is strictly prohibited.
 * This file is proprietary and confidential.
 * 
 */

import { DefaultButton, Icon, Link, PrimaryButton, Selection, Stack, StackItem, Text, TextField, ThemeSettingName, initializeIcons } from '@fluentui/react';
import React from 'react';
import ProgressList from '../components/ProgressList/ProgressList';
import { Button, Field, Input, LargeTitle, Spinner, Title3 } from '@fluentui/react-components';
import { toHaveStyle } from '@testing-library/jest-dom/matchers';
import PhoneField from '../components/PhoneField/PhoneField';

import debounce from 'lodash/debounce';
import env from '../env/env';
import axios from 'axios';
import Store from '../redux/ReduxStore';
import Registration_Type, { Signup_Registration_Type } from '../lib/RegistrationType/RegistrationType';

export interface RegisterStep1View_Props {
  canContinue: (canContinue: boolean) => void
}

export default class RegisterStep1View extends React.Component <RegisterStep1View_Props> {

  private styles: any = {};
  private email_last_changed: Date = new Date();

  public constructor(props: any) {
    super(props);

    this.state = {
      email_valid: false,
      email_valid_attempted: false,
      email_validating: false,

      business_name: "",
      first_name: "",
      last_name: "",
      email_address: "",
      phone_number: ""
    };

    // define the views styles...
    this.styles = {
      form_wrap: {
        width: 400
      },

      name_wrap: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        gap: 15
      }
    }

    // define the debounce function for email address validation...
    // with 500ms delay to allow for email input to complete...
    this.validate_email_address = debounce(this.validate_email_address, 500);
    this.handle_input_change = debounce(this.handle_input_change, 500);
  }

  /**
   * Built in react hook for component mounting to load data thus
   * allowing for setState to continue...
   * 
   */
  public componentDidMount(): void {
    try {
      // dynamically load the redux state if it exists...
      this.load_redux_state_if_exists();

    } 
    catch(error) {
      throw error;
    } 
  }

  /**
   * Function used to validate the email address and update the redux
   * state accordingly...
   * 
   */
  public validate_email_address = async () => {
    try {

      // update the user interface to display the email validating... 
      this.setState({
        email_validating: true
      });

      // do we have anything in the email input?
      if((this.state as any).email_address == "") {
        // reset and remove the validation on the form...
        this.setState({
          email_validating: false,
          email_valid_attempted: false,
          email_valid: false
        });

      }
      else {
        // do some basic literal validations before we make any backend requests...
        const emailAddress = (this.state as any).email_address;
        if(emailAddress.includes("@") && emailAddress.includes(".")) {

          // validate the email address against the backend API service...
          const apiService = env.API_REMOTE_SERVER;
          const response = await axios.post(
            apiService + "v1/account/register/email/verify", {
                email: emailAddress
            },
            {
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }
          );

          if(response.status == 200) {
            const responseData = response.data;
            if(responseData.response_data.email_used) {
              // update the state with the response data...
              this.setState({
                email_validating: false,
                email_valid_attempted: true,
                email_valid: false
              });

              // re run the input validation to ensure that the callback is executed on chamge..
              this.handle_input_change();

            }
            else {
              // update the state with the response data...
              this.setState({
                email_validating: false,
                email_valid_attempted: true,
                email_valid: true
              });

              // re run the input validation to ensure that the callback is executed on chamge..
              this.handle_input_change();

            }
          }
          else {
            // re run the input validation to ensure that the callback is executed on chamge..
            this.handle_input_change();
            throw "Could not validate the email address against our API service.";
          }

        }
        else {
          this.setState({
            email_validating: false,
            email_valid: false,
            enail_valid_attempted: true
          });

          // re run the input validation to ensure that the callback is executed on chamge..
          this.handle_input_change();
        }
      } 

    }
    catch(error) {
      throw error;
    }
  }

  /**
   * Function used to store the data into our shared redux state
   * to ensure that the data is persistant should the user reverse
   * the page and return to here...
   * 
   */
  private update_redux_state = (): void => {
    try {
      const state = this.state as any;
      Store.dispatch({type: "UPDATE_STEP_1", payload: {
        first_name: state.first_name,
        last_name: state.last_name,
        email_address: state.email_address,
        phone_number: state.phone_number,
        business_name: state.business_name
      }});

    }
    catch(error) {
      throw error;
    }
  }

  /**
   * Function used to update the current state if there is data already
   * stored in the redux session state, IE.. the user has already been
   * onto this page and will now return.
   * 
   */
  private load_redux_state_if_exists = (): void => {
    try {
      let hasCompletedReduxState: boolean = true;

      const reduxState: any = Store.getState();
      for(let key in reduxState.step_1) {
        if(reduxState.step_1[key] == "") {
          hasCompletedReduxState = false;
        }
      }

      // update the local state if we have a completed redux state...
      if(hasCompletedReduxState) {
        this.setState({
          first_name: reduxState.step_1.first_name,
          last_name: reduxState.step_1.last_name,
          email_address: reduxState.step_1.email_address,
          phone_number: reduxState.step_1.phone_number,
          business_name: reduxState.step_1.business_name
        });

        // re-call the validate email function to ensure that the email valid state updates.. 
        this.validate_email_address();
      }

    }
    catch(error) {
      throw error;
    }
  }

  /**
   * Function used to handle the input change for the form data
   * and validate it against the form...
   * 
   * @param String form_index
   * @param String value
   * 
   */
  public handle_input_change = (): void  => {
    try {
      // this.setState({
      //   [form_index]: value
      // });

      // do the validation stuff to see if we can progress...
      let canContinue = true;
      const stateRequirements = [
        "first_name",
        "last_name",
        "email_address",
        "phone_number",
        "business_name"
      ];
      const state = this.state as any;
      for(let stateIndex of stateRequirements) {
        if(state[stateIndex] == "") {
          canContinue = false;
        }
      }

      // also ensure that we have a valid email address...
      if(!state.email_valid) {
        canContinue = false;
      }

      // update the refux state if we can continue..
      if(canContinue) {
        this.update_redux_state();
      }

      // execute the props callback to update the root component...
      this.props.canContinue(canContinue);

    }
    catch(error) {
      throw error;
    }
  }

  /**
   * Function used to get the validation state (FluentUI string value) of
   * the email address field, based on the current state value...
   * 
   * @return String "success" | "error" | ""
   * 
   */
  private get_email_address_valid_value = () => {
    const state = this.state as any;

    if(state.email_valid_attempted && state.email_valid) {
      return "success";
    }
    else if(state.email_valid_attempted) {
      return "error";
    }
    else {
      // no validation attempt has been made on the email yet...
      return "none";
    }
  }

  /**
   * Function used to get the validation message that will be displayed
   * in the FluenUI field...
   * 
   * @return String The validation message to be displayed...
   * 
   */
  private get_email_address_valid_message = () => {
    if(this.get_email_address_valid_value() == "success") {
      return "Your email has been verified."
    }
    else if(this.get_email_address_valid_value() == "error") {
      return "This email is either invalid or already in use.";
    } 
    else {
      return "";
    }
  }

  /**
   * Function used tor render the view...
   * 
   */
  public render() {
    const state = this.state as any;

    return (
      <div>
        <StackItem>

          {Registration_Type.get_registration_type() == Signup_Registration_Type.PARTNER? (
            <Title3>Become a 121 Digital partner</Title3>
          ) : (
            <Title3>Get Started with 121 Digital</Title3>
          )}
          {/* <Text variant={"xxLarge"}><b>Get Started with 121 Digital</b></Text> */}
          <p>Let's get your basic account details.</p>
        </StackItem>

        <br />

        <StackItem>
          <div style={this.styles.form_wrap}>
            <div style={this.styles.name_wrap}>
              <div style={{width: (200 - 15/2)}}>
                <Field label="First Name">
                  <Input 
                    value={state.first_name}
                    size='medium' 
                    style={{width: "100%"}} 
                    onChange={(event: any) => {
                    this.setState({
                      first_name: event.target.value
                    });
                    this.handle_input_change();
                  }}/>
                </Field>
              </div>
              <div style={{width: (200 - 15/2)}}>
                <Field label="Last Name">
                  <Input
                    value={state.last_name}
                    size="medium" 
                    style={{width: "100%"}} 
                    onChange={(event: any) => {
                    this.setState({
                      last_name: event.target.value
                    });
                    this.handle_input_change();
                  }}/>
                </Field>
              </div>
            </div>

            <br />

            <Field label="Business Name">
              <Input 
                value={state.business_name}
                size="large" 
                style={{width: "100%"}} 
                onChange={(event: any) => {
                this.setState({
                  business_name: event.target.value
                });
                this.handle_input_change();
              }}/>
            </Field>

            <br />

            <Field 
              label="Email Address"
              validationState={this.get_email_address_valid_value()}
              validationMessage={this.get_email_address_valid_message()}
              
            >
              <Input 
                value={state.email_address}
                size="large" 
                style={{width: "100%"}} 
                onChange={(event: any) => {
                this.setState({
                  email_address: event.target.value
                });

                // validate the email address...
                this.validate_email_address();
              }}/>
            </Field>
            <br />

            {state.email_validating? (
              <div>
                <Spinner size='tiny' style={{float: "left"}} label="Verifying email address..." />
                <br /><br />
              </div>
            ): (
              <div />
            )}

            {/* <TextField  
              label='Email Address' 
              type='email'
            /> */}
            {/* <br /> */}
            {/* <Field 
              label="Phone Number"
              validationState='success'
              validationMessage={"Your phone number has been verified."}
            >
              <Input size="large" style={{width: "100%"}}/>
            </Field> */}

            <PhoneField value={state.phone_number} onChange={(event: any) => {
              this.setState({
                phone_number: event.target.value
              });
              this.handle_input_change();
            }} />
          </div>
        </StackItem>
      </div>
    );
  }

}
