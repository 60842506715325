/**
 * 121 Digital Get Started Form
 * 
 * Project: 121 Digital Get Started Form
 * Company: 121 Digital Services Limited
 * 
 * Description: [A brief description of the project. E.g., This project is designed to provide users with a seamless
 * onboarding experience through a digital form.]
 * 
 * Author: James Gibbons <jgibbons@121digital.co.uk>
 * Created Date: 24/06/2024
 * 
 * © 2024 121 Digital Services Limited. All rights reserved.
 * 
 * This source code is the property of 121 Digital Services Limited. Unauthorized copying,
 * distribution, or modification of this file, via any medium, is strictly prohibited.
 * This file is proprietary and confidential.
 * 
 */

import { Icon, Text } from '@fluentui/react';
import React from 'react';
import ProgressListItem from './ProgressListItem';

export interface ProgressListProps {
  current_step: number,
  max_progression?: number
};

export default class ProgressList extends React.Component<ProgressListProps> {

  private styles: any = {};

  public constructor(props: any) {
    super(props);

    // generate the max progression if it is not defined...
    let max_progression = 0;
    if(typeof this.props.max_progression == "undefined") {
      max_progression = 1;
    }
    else {
      max_progression = this.props.max_progression;
    }

    // generate styles...
    this.styles = {
      progress_wrapper: {
        borderLeftStyle: "solid",
        borderLeftWidth: 1,
        borderLeftColor: "#E7E6E6",
        height: "100%",

        padding: 25,
        paddingRight: 50,

        display: "flex",
        flexDirection: "column",
        gap: 15
      }
    };
  }

  /**
   * Render the component... 
   * 
   */
  public render() {
    return (
      <div style={this.styles.progress_wrapper}>
        <ProgressListItem 
          is_active={(this.props.current_step === 1)} 
          title="Step 1"
          description="
          Provide your details, that will be used to open your new
          account with 121 Digital."
          disabled={false}
        />
        <ProgressListItem 
          is_active={(this.props.current_step === 2)} 
          title="Step 2"
          description="
            Arrange a time to speak with one of our small business website
            design experts.
          "
          disabled={(this.props.current_step < 2)}
        />
        <ProgressListItem 
          is_active={(this.props.current_step === 3)} 
          title="Step 3"
          description="
            Complete your account registration with 121 Digital Services.
          "
          disabled={(this.props.current_step < 3)}
        />
      </div>
    );
  }

}