/**
 * 121 Digital Get Started Form
 * 
 * Project: 121 Digital Get Started Form
 * Company: 121 Digital Services Limited
 * 
 * Description: [A brief description of the project. E.g., This project is designed to provide users with a seamless
 * onboarding experience through a digital form.]
 * 
 * Author: James Gibbons <jgibbons@121digital.co.uk>
 * Created Date: 24/06/2024
 * 
 * © 2024 121 Digital Services Limited. All rights reserved.
 * 
 * This source code is the property of 121 Digital Services Limited. Unauthorized copying,
 * distribution, or modification of this file, via any medium, is strictly prohibited.
 * This file is proprietary and confidential.
 * 
 */

import { DefaultButton, Icon, Link, PrimaryButton, Selection, Stack, StackItem, Text, TextField, ThemeSettingName, initializeIcons } from '@fluentui/react';
import React from 'react';
import ProgressList from '../components/ProgressList/ProgressList';
import { Button, Field, Input, LargeTitle, Spinner, Title3 } from '@fluentui/react-components';
import { toHaveStyle } from '@testing-library/jest-dom/matchers';
import PhoneField from '../components/PhoneField/PhoneField';

import backgroundImage from '../assets/backgrounds/default.jpg';
import logo from './../assets/branding/brand.png';

import RegisterStep1View from './RegisterStep1Vew';
import RegisterStep2View from './RegisterStep2View';
import RegisterStep3View from './RegisterStep3View';
import PasswordDialog from '../components/PasswordDialog/PasswordDialog';
import CreatingView from './CreatingView';
import CreatedView from './CreatedView';
import env from '../env/env';
import axios from 'axios';
import Store from '../redux/ReduxStore';
import Registration_Type, { Signup_Registration_Type } from '../lib/RegistrationType/RegistrationType';

initializeIcons();

export default class RegisterView extends React.Component {

  private styles: any = {};

  public constructor(props: any) {
    super(props);

    // define default state...
    this.state = {
      current_step: 1,
      can_progress: false,
      display_password_dialog: false
    };

    this.styles = {
      root_container: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        width: "100%",
        height: "100%",

        position: "absolute",
        top: 0,
        left: 0
      
      },
      container: {
        width: 1000,
        minHeight: 600,

        backgroundColor: "white",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 140,

        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        padding: 25,

        paddingBottom: 75
      },

      flex_wrap: {
        width: "100%",
        display: "flex"
      },

      logo_wrapper: {
        display: "flex",
        paddingBottom: 35
      }
    };
  }

  /**
   * Function used to check if we have a next step to progress
   * to, based on the step state value...
   * 
   * @return Boolean Do we have a next step..
   * 
   */
  public has_next_step() {
    const state = this.state as any;
    if(state.current_step == 3) {
      return false;
    }
    else if(state.current_step < 3) {
      return true;
    }
    else {
      return false;
    }
  }

  /**
   * Function used to check if we have another step to move back to..
   * 
   * @return boolean Has next step...
   * 
   */
  public has_last_step() {
    const state = this.state as any;
    if(state.current_step == 1) {
      return false;
    }
    else if(state.current_step > 1) {
      return true;
    }
  }

  /**
   * Changes the registration step to be the next one incrementally;
   * 
   */
  public next_step = () => {
    const state = this.state as any;

    // check if we need to display the set password dialog...
    if(state.current_step == 2) {
      if(state.display_password_dialog) {

        // partner accounts do not get asked to add a billing method on signup...
        if(Registration_Type.get_registration_type() == Signup_Registration_Type.PARTNER) {
          this.setState({
            current_step: state.current_step +2,
            can_progress: false,
  
            // hide the password dialog...
            display_password_dialog: false
          });

          // // since we will not be adding a card, we must update the redux state with placeholder data...
          // Store.dispatch({

          // });

          // complete the registration process... 
          this.submit_data_create_account();
        }
        else {
          this.setState({
            current_step: state.current_step +1,
            can_progress: false,
  
            // hide the password dialog...
            display_password_dialog: false
          });
        }
      }
      else {
        // before we progress, we will set the display password dialog to view..
        this.setState({
          display_password_dialog: true
        })

      }
    }
    else {
      this.setState({
        current_step: state.current_step +1,
        can_progress: false
      });
    }
  }

  /**
   * Changes the registration step to be last next one incrementally;
   * 
   */
  public last_step = () => {
    const state = this.state as any;
    this.setState({
      current_step: state.current_step -1,
      can_progress: false
    });
  }

  /**
   * Gets the current steps React JSX component to be rendered based on the
   * current step that is set in the state..
   * 
   * @return React.Component The current steps react component view..
   * 
   */
  private get_current_step_view = () => {
    const state = this.state as any;
    if(state.current_step == 1) {
      return (
        <RegisterStep1View canContinue={(canContinue: boolean) => {
          this.setState({
            can_progress: canContinue
          })
        }} />
      );
    }
    else if(state.current_step == 2) {
      return (
        <RegisterStep2View canContinue={(canContinue: boolean) => {
          this.setState({
            can_progress: canContinue
          });
        }} />
      );
    }
    else if(state.current_step == 3) {
      return (
        <RegisterStep3View canContinue={(canContinue: boolean) => {
          this.setState({
            can_progress: canContinue
          });
        }} />
      );
    }
    else if(state.current_step == 4) {
      return (
        <CreatingView />
      );
    }
    else if(state.current_step == 5) {
      return (
        <CreatedView />
      );
    }
  }

  public set_can_progress(can_progress: boolean) {
    console.log(can_progress);

    this.setState({
      can_progress: can_progress
    });
  }

  /**
   * Function used to submit the form and attempt to create the new
   * account against the backend API service...
   * 
   */
  public submit_data_create_account = async () => {
    try {
      const reduxState = Store.getState();

      console.log(reduxState.saved_card.has_saved_card,);
        
      // redirect the user to the next step....
      // this.next_step();

      // validate the email address against the backend API service...
      const apiService = env.API_REMOTE_SERVER;
      const response = await axios.post(
        apiService + "v1/account/register", {
          
          account_data: {
            first_name: reduxState.step_1.first_name,
            last_name: reduxState.step_1.last_name,
            email_address: reduxState.step_1.email_address,
            phone_number: reduxState.step_1.phone_number,
            business_name: reduxState.step_1.business_name
          },

          address_data: {
            line_1: reduxState.step_2.address.line_1,
            line_2: reduxState.step_2.address.line_2,
            city: reduxState.step_2.address.city,
            country: reduxState.step_2.address.country,
            county: reduxState.step_2.address.county,
            geo_code: reduxState.step_2.address.geo_country,
            postal_code: reduxState.step_2.address.postal_code
          },

          password_data: {
            password: reduxState.password.password,
            confirm_password: reduxState.password.confirm_password
          },

          payment_method_data: {
            has_saved_card: reduxState.saved_card.has_saved_card,
            name_on_card:   reduxState.saved_card.name_on_card,
            stripe_pm_id:   reduxState.saved_card.stripe_pm_id
          }

        },
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }
      );

      if(response.status == 200) {
        
        const responseData = response.data;
        if(responseData.response_data.account_created) {
          // update the state with the response data...
          // this.setState({
          //   email_validating: false,
          //   email_valid_attempted: true,
          //   email_valid: false
          // });

          this.next_step();

          // re run the input validation to ensure that the callback is executed on chamge..
          // this.handle_input_change();

        }
        else {
          // update the state with the response data...
          // this.setState({
          //   email_validating: false,
          //   email_valid_attempted: true,
          //   email_valid: true
          // });

          // re run the input validation to ensure that the callback is executed on chamge..
          // this.handle_input_change();

          console.log(response);
          throw "Could not create account...";

        }
      }

    }
    catch(error) {
      throw error;
    }
  }

  /**
   * Function used to render the template...
   * 
   */
  public render() {
    const state = this.state as any;
    console.log(state);


    return (
      <div style={this.styles.root_container}>
        <div style={this.styles.container}>

          {state.current_step >= 4? (

            this.get_current_step_view()

          ) : (
            <div style={this.styles.flex_wrap}>
              <div style={{width: "50%", paddingTop: 30}}>
                <Stack>
                  <PasswordDialog 
                    isOpen={state.display_password_dialog} 
                    onDismiss={() => {
                      this.setState({
                        display_password_dialog: false
                      });
                    }} 
                    onComplete={() => {
                      this.next_step();
                    }}
                  />
                  {this.get_current_step_view()}

                  <br />

                  <StackItem>
                    {/* <DefaultButton 
                      onClick={this.last_step} 
                      style={{marginRight: 15}}
                      disabled={!this.has_last_step()}
                    >Back</DefaultButton>
                    <PrimaryButton
                      onClick={this.next_step}
                      disabled={!this.has_next_step()}
                    >Next Step</PrimaryButton> */}

                    <Button 
                      appearance='outline'
                      onClick={this.last_step}
                      style={{marginRight: 15}}
                      disabled={!this.has_last_step()}
                    >Last Step</Button>

                    {this.has_next_step()? (
                      <Button 
                        appearance='primary'
                        onClick={this.next_step}
                        disabled={!this.has_next_step() || !state.can_progress}
                      >Next Step</Button>
                    ): (
                      <Button 
                        appearance='primary'
                        onClick={this.submit_data_create_account}
                        disabled={!state.can_progress}
                      >Complete Setup</Button>
                    )}
                  </StackItem>
                </Stack>
              </div>
              <div style={{width: "50%"}}>
                <ProgressList current_step={state.current_step} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

}