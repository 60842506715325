import React from 'react';

export default class Footer extends React.Component {

  private styles: any = {};

  public constructor(props: any) {
    super(props);
    this.styles = {
      footer: {
        width: '100%',
        height: 50
      },
      wrapper: {
        width: 1000,
        height: 50,
        margin: "auto",
        // background: "red",

        paddingLeft: 25,
        paddingRight: 25
      }
    };
  }

  public render() {
    return (
      <div style={this.styles.footer}>
        <div style={this.styles.wrapper}>

        </div>
      </div>
    );
  }

}